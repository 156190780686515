.swiper {
    width: 100%;
    height: fit-content;
}

.swiper-wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    overflow: hidden;
    font-size: 18px;
    background: rgba(222, 222, 222, 0.06);
    border-radius: 6px;
    height: 100%;
    padding: 12px;
}

.selected {
    border: 1px solid #2142FF;
}